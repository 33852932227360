import * as style from './style.module.css';

import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react';

import CustomButton from '../components/button';
import IDEImage from '../images/IDEImage';
import Layout from '../components/layout';
import React from 'react';
import Seo from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { marked } from 'marked';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Service = ({
  data: {
    strapi: {
      service: { data },
    },
  },
}) => {
  const breakpoints = useBreakpoint();
  return (
    <Layout>
      <Seo title={data.attributes.page.title} />
      {breakpoints.xl ? (
        <Flex zIndex={-999} position='absolute' top='0'>
          <StaticImage
            alt='bg'
            src='../images/TopBG.svg'
            quality={100}
            width={8400}
            placeholder='blurred'
          />
        </Flex>
      ) : null}
      <Flex alignItems='center' flexDirection='column' mt='75px'>
        <Text
          textTransform='uppercase'
          letterSpacing='0.03em'
          fontFamily='Poppins'
          fontWeight='black'
          lineHeight='109%'
          color='#303056'
          fontSize={
            breakpoints.xl
              ? '82px'
              : !breakpoints.xs && data.id === '1'
              ? '60px'
              : !breakpoints.xs && data.id !== '1'
              ? '36px'
              : !breakpoints.xs && data.id === '1'
              ? '72px'
              : '48px'
          }
        >
          {data.attributes.page.title}
        </Text>
        <Text
          textTransform='uppercase'
          letterSpacing='0.485em'
          fontFamily='Poppins'
          fontWeight='medium'
          lineHeight='109%'
          fontSize={{ base: '18px', xl: '24px' }}
          color='white'
        >
          {data.attributes.page.subtitle}
        </Text>
      </Flex>
      <Flex
        justifyContent='center'
        mt='87px'
        mx={breakpoints.xl2 ? '0' : !breakpoints.xl ? '4' : '8'}
        flexDirection={{ base: 'column', xl: 'row' }}
        alignItems={{ base: 'center', xl: 'initial' }}
      >
        <Flex
          mt={{ base: '-16', xl: 'unset' }}
          mx={{ base: '-4', xl: 'inherit' }}
          overflow='hidden'
          width={{base: '550px', xl: data.attributes.illustration.data.attributes.width}}
        >
          <Image
            alt={data.attributes.illustration.data.attributes.alternativeText}
            src={`${process.env.GATSBY_DOMAIN_URL}/api${data.attributes.illustration.data.attributes.url}`}
            fit={{ base: 'cover', xl: 'unset' }}
            width={data ? '840px' : '100%'}
            height='100%'
          />
        </Flex>
        <Flex
          flexDirection='column'
          alignItems={{ base: 'center', xl: 'flex-end' }}
          ml={breakpoints.xl2 ? '0' : !breakpoints.xl ? '0' : '8'}
        >
          <Text
            fontSize={
              breakpoints.xl2
                ? '55px'
                : !breakpoints.xs
                ? '24px'
                : !breakpoints.xl
                ? '40px'
                : '55px'
            }
            textTransform='uppercase'
            fontFamily='Poppins'
            lineHeight='136%'
            fontWeight='bold'
            textAlign={{ base: 'center', xl: 'right' }}
            color='white'
            maxW='545px'
            mt={{ base: '8', xl: '0' }}
          >
            {data.attributes.title}
          </Text>
          <Box
            w='56px'
            h='2px'
            borderRadius='10px'
            bgColor='black'
            mt='28px'
            mb='26px'
          />
          <div
            dangerouslySetInnerHTML={{
              __html: marked.parse(data?.attributes?.description),
            }}
            className={style.descriptionText}
            style={{
              textAlign: breakpoints.xl ? 'justify' : 'unset',
              fontFamily: 'Montserrat',
              fontWeight: '500',
              lineHeight: '167%',
              fontSize: '18px',
              color: 'white',
              maxWidth: '500px',
            }}
          />
          <Flex
            justifyContent='center'
            w='100%'
            mt={{ base: '10', xl: '14' }}
            mb={{ base: '16', xl: 'unset' }}
          >
            <CustomButton buttonText={'Our Contact'} />
          </Flex>
        </Flex>
      </Flex>
      {breakpoints.xl ? (
        <>
          <Flex justifyContent='center'>
            <StaticImage
              src='../images/RoundArrow.png'
              placeholder='blurred'
              layout='fixed'
              alt='Arrow'
            />
          </Flex>
          <Flex alignItems='center' flexDirection='column' mt='16'>
            <VStack alignItems='center'>
              <Text
                textTransform='uppercase'
                fontFamily='Montserrat'
                letterSpacing='0.27em'
                fontWeight='medium'
                lineHeight='10%'
                fontSize={breakpoints.full ? '55px' : breakpoints.xl2 ? '40px': '35px'}
                color='#37404A'
              >
                ENJOY THE SERVICES PROVIDED BY
              </Text>
              <Text
                textTransform='uppercase'
                fontFamily='Montserrat'
                letterSpacing='0.27em'
                fontWeight='medium'
                lineHeight='40%'
                fontSize={breakpoints.full ? '55px' : breakpoints.xl2 ? '40px': '35px'}
                color='#DD4078'
              >
                OUR TEAM OF PROFESSIONAL PROGRAMMERS
              </Text>
            </VStack>
            <Box mt='24' mb='44' w={840} h={548}>
              <IDEImage />
            </Box>
          </Flex>
        </>
      ) : null}
    </Layout>
  );
};

export default Service;

export const query = graphql`
  query serviceQuery($id: ID!) {
    strapi {
      service(id: $id) {
        data {
          id
          attributes {
            description
            title
            page {
              alias
              subtitle
              title
            }
            illustration {
              data {
                attributes {
                  url
                  alternativeText
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;
